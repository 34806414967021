<template>
  <div class="weui-tab">
    <div class="weui-tab__bd public-container-no-tarbar">
      <div class="margin-bottom-10 margin-top-10">
        <template v-if="submitStatus === 'S'" v-desc="'答题正常完成'" >
          <template v-if="needPictureReview === 'Y'" v-desc="'需要上传图片'">
            <div class="title">上传答题相关图片</div>
            <div class="upLoadContent">
              <Uploader v-model="fileList" :before-read="beforeRead" :max-count="20" :max-size="20 * 1024 * 1024"/>
              <div class="weui-cells margin-top-0">
              <div class="weui-cell" href="javascript:;">
                <div class="weui-cell__hd">
                  <label class="weui-label">答题备注</label>
                </div>
                <div class="weui-cell__bd">
                  <p>
                    <input
                      class="weui-input number-input"
                      v-model="answerRemark"
                      type="text"
                      maxlength=""
                      placeholder="请输入答题备注"
                    />
                  </p>
                </div>
              </div>
            </div>
            </div>
            <!-- <div class="weui-cells margin-top-0">
              <div class="weui-cell" href="javascript:;">
                <div class="weui-cell__hd">
                  <label class="weui-label">答题备注</label>
                </div>
                <div class="weui-cell__bd">
                  <p>
                    <input
                      class="weui-input number-input"
                      v-model="answerRemark"
                      type="text"
                      maxlength=""
                      placeholder="请输入答题备注"
                    />
                  </p>
                </div>
              </div>
            </div> -->
            <a
              href="javascript:;"
              @click="confirmUpload"
              class="
                margin-top-10
                btn-attention-mini
                public-btn-activity
                public-btn-invite
                public-btn-invite-type
              "
              >确认上传</a
            >
          </template>
          <template v-else v-desc="'无需上传图片 或已经完成上传图片'">
            <div class="title">恭喜您完成问卷</div>
            <div class="completedTasks">
              <!-- <img  alt="完成问卷" src="../assets/completedTasks.svg" /> -->
              <img  alt="完成问卷" src="../assets/mp_qr.jpg" />
              <h6>关注公众号获取更多问卷</h6>
            </div>
            <router-link
              :to="{name: 'home'}"
              class="margin-top-10
              btn-attention-mini
              public-btn-activity
              public-btn-invite
              public-btn-invite-type"
            >
              返回首页
            </router-link>
          </template>
        </template>
        <template v-else >
          <!-- <div class="text-center">
            <img class="sadFace" alt="悲伤" src="../assets/sad-face.png"   />
          </div> -->
          <div class="title large">对不起</div>
          <div class="title" v-if="submitStatus === 'D'" >您已被筛选</div>
          <div class="title" v-else-if="submitStatus === 'F'" >配额已满</div>
          <div class="completedTasks">
            <img alt="完成问卷" src="../assets/mp_qr.jpg" />
            <h6>关注公众号获取更多问卷</h6>
          </div>
          <router-link
            :to="{name: 'home'}"
            class="margin-top-10
            btn-attention-mini
            public-btn-activity
            public-btn-invite
            public-btn-invite-type"
          >
            返回首页
          </router-link>
        </template>
        
        
      </div>
    </div>
  </div>
</template>
<script>
import Uploader from 'vant/lib/uploader';
import { v4 as uuidv4 } from 'uuid';
import { isInWeiXin } from '../utils/index'
import { uploadAnswerPictures } from '../api/index'
import { getParams } from '../utils/index'
import 'vant/lib/uploader/style'
// import 'vant/lib/toast/style'
import wxauth from '../mixin/wxauth'

export default {
  mixins: [wxauth],
  name: "uploadPicture",
  components: {
    Uploader
  },
  data () {
    return {
      isWx: isInWeiXin(),
      answerRemark: '', // 答题备注
      fileList: [],
      returnPicArr: [],
      OSSClient: null,
      needPictureReview: 'Y', // 是否需要图片审核：Y-是，N-不是
      submitStatus: 'S', // S提交成功 D被甄别 F配额满
      // region: 'oss-cn-hangzhou',
      // accessKeyId: 'STS.NTfMtuapqYnEad9SXgqwMZMHg',
      // accessKeySecret: '53gN1A6dy65hu3GjiB3e9RYqtWp1CEvaZF19wRaSABXx',
      // stsToken: 'CAISiQJ1q6Ft5B2yfSjIr5fTBs7BjK9Q7qyuY0KIt1gyffhhtYjjhTz2IHlLe3FoAeAbtfw1n2tT5/oblo0rEMEVGxTKYY4hv84MtF+rPYCE6p3t5PlU18P7STedVETwg8KKm0w3/xeFKJmXcFnhunJZ49mTLEePPTGMNZmOluQUDLA+VRW5ajxrHcpfIhAYxuYRKXrWM4TGUHeo+B2yanBloQ1hk2hyxL2iy8mHkHrkgUb91/UeqvaaQPHmTbE1bcYiDo7rgrMpJvufjHUM0XUQqvcq1p4j0Czco9SQD2NW5xi7KOfO+rVtVlQgOfdiQ/YU8aOkz6Yj4b2Pzp6Z0R9IOvpOQ75Gxh1edWavGoABTm78Ua9X5iQQAIGfa91MqkY+QuA8cOArj+x00FekodAPy/mmFLSYXwm9c/quAVwlAHN7xPdyCfusOnggmF/BvUZVI3iHVJny6siVS0gWFPCM7XOaRh/q3r6ZOZErVAqvVB3RHSoIrcam8rfUlULJz0DYSr/hcWWCngy5XMgJhbc=',
      // bucket: 'bjm-survey-test',
      // answerIdentifier: 'M12bP85dvy9vaenlxGOQ',
      region: '',
      accessKeyId: '',
      accessKeySecret: '',
      stsToken: '',
      bucket: '',
      answerIdentifier: ''
    }
  },
  created () {
    this.wxAuthInit()
  },
  mounted () {
    // this.initOSSClient()
  },
  methods: {
    doWxAuthCb () {
      this.initUrlParams()
    },
    // 获取URL上的参数
    initUrlParams () {
      this.region = getParams('ossRegion')
      this.accessKeyId = getParams('ossAccessKeyId')
      this.accessKeySecret = getParams('ossKeySecret')
      this.stsToken = getParams('ossStsToken')
      this.bucket = getParams('ossBucket')
      this.answerIdentifier = getParams('answerIdentifier')
      this.needPictureReview = getParams('needPictureReview')
      this.submitStatus = getParams('submitStatus')
      if (getParams('needPictureReview') === 'Y') {
        this.initOSSClient()
      }
    },
    // 对阿里云oss对象存储初始化
    initOSSClient () {
      const client = new window.OSS({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
        region: this.region,
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: this.accessKeyId,
        accessKeySecret: this.accessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: this.stsToken,
        // 填写Bucket名称。
        bucket: this.bucket
      });
      this.OSSClient = client
    },
    beforeRead (file) {
      const fileList = this.fileList
      const duplicate = fileList.find((item) => {
        return item.file.name === file.name
      })
      if (duplicate) {
        this.$toast.info('图片重复')
        return false
      }
      return true
    },
    // 上传图片
    async doUploadAnswerPictures () {
      let params = {
        param: {
          answerIdentifier: this.answerIdentifier,
          pictures: this.returnPicArr,
          answerRemark: this.answerRemark
        }
      }
      if (!this.answerRemark) {
        delete params.param.answerRemark
      }
      let res = {}
      try {
        res = await uploadAnswerPictures(params)
        this.$toast.loading('加载中', { duration: 10000 });
        this.returnPicArr = []
        if (res.code === '0000') {
          this.$toast.info('图片上传成功')
          this.needPictureReview = 'N'
          // setTimeout(() => {
          //   this.$router.push({name: 'home'})
          // }, 2000)
        } else {
          this.$toast.warn('上传图片异常，请稍后再试')
        }
      } catch (err) {
        this.$toast.warn('上传图片异常，请稍后再试')
      }
    },
    confirmUpload () {
      if (this.fileList.length > 0) {
        this.fileList.forEach((item) => {
          this.putObject(item)
        });
      } else {
        this.$toast.info('请选择上传图片')
      }
    },
    async putObject (file) {
      console.log('file:', file)
      this.$toast.loading('图片上传中...', { duration: 10000 });
      // const data = new window.OSS.Buffer([file.content]);
      // const data = new Blob([file])
      const uuid = uuidv4()
      const fileName = `answerimg/${uuid}${file.file.name}`
      try {
        // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
        // 您可以通过自定义文件名（例如exampleobject.txt）或文件完整路径（例如exampledir/exampleobject.txt）的形式实现将数据上传到当前Bucket或Bucket中的指定目录。
        // data对象可以自定义为file对象、Blob数据或者OSS Buffer。
        const result = await this.OSSClient.put(
          fileName,
          file.file
        );
        this.returnPicArr.push(result.name)
        if (this.returnPicArr.length === this.fileList.length) {
          this.doUploadAnswerPictures()
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
};
</script>
<style lang="css" scoped>
.public-container-no-tarbar{
  margin: unset;
}
.weui-btn.public-btn-login{
  padding: unset;
}
.title{
  text-align: center;
}
.title.large {
  font-size: 22px;
  margin-bottom: 6px;
}
.upLoadContent{
  padding: 20px;
}
.completedTasks{
  text-align: center;
  padding: 20px 0;
}
.completedTasks>img{
  width: 50%;
}
.sadFace{
  width: 80px !important;
  /* clip: rect(50px 50px 50px 50px) */
}
.weui-cells{
  background-color: #f7f8fa;
}
</style>
